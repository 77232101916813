<template>
  <div v-if="organization">
    <div :class="isDialog ? 'd-flex pl-4 pr-4 pt-4' : 'd-flex mb-2'">
      <div
        v-if="isDialog"
        class="text-h5 rm-dialog-title font-weight-bold flex-grow-1"
      >
        Организация: {{ organizationName }}
      </div>
      <div v-else class="flex-grow-1">
        <strong>{{ organizationName }}</strong>
      </div>
      <img
        v-if="organization && organization.logo"
        :src="organization.logo"
        alt="Логотип организации"
        class="logo"
      />
    </div>

    <v-chip
      v-if="organization.educational_category"
      color="primary"
      small
      outlined
      :class="isDialog ? 'mr-1 ml-4 mt-2' : 'mr-1 mb-1'"
      >Образовательная организация</v-chip
    >
    <v-card-text
      class="text-body-1"
      :class="isDialog ? 'pa-5 black--text' : 'pa-0'"
    >
      <option-row
        ><span slot="title">ID Организации:</span>
        <talent-link
          :path="`admin/package/organization/${organization.id}/change/`"
          >{{ organization.id }}</talent-link
        >
      </option-row>

      <v-expansion-panels
        v-if="organization.description && isDialog"
        flat
        class="black--text text-body-1 pa-0 mb-1"
      >
        <v-expansion-panel @change="isOpenDescription = !isOpenDescription">
          <v-expansion-panel-header class="text-body-1 d-block pa-0"
            ><template #actions>
              <div class="link">
                {{ isOpenDescription ? "Скрыть" : "Показать" }}
              </div> </template
            ><span class="secondary--text text--lighten-2 mr-1">Описание:</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ organization.description }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <option-row v-if="address" title="Адрес:">{{ address }}</option-row>

      <option-row v-if="contactFullName" title="Контактное лицо:">{{
        contactFullName
      }}</option-row>

      <option-row v-if="organization.contact_email" title="Эл.почта:"
        ><a :href="`mailto:${organization.contact_email}`">{{
          organization.contact_email
        }}</a></option-row
      >

      <option-row
        v-if="organization.contact_phone && isDialog"
        title="Телефон контактного лица:"
        >{{ organization.contact_phone }}</option-row
      >

      <option-row v-if="createDate" title="Дата создания:">{{
        createDate
      }}</option-row>

      <option-row v-if="updatedAtDate && isDialog" title="Дата обновления:">{{
        updatedAtDate
      }}</option-row>

      <option-row
        v-if="organization.parent_organization"
        title="Родительская организация:"
      >
        <talent-link
          :path="`admin/package/organization/${organization.parent_organization}/change/`"
        >
          {{ organization.parent_organization }}
        </talent-link>
      </option-row>

      <option-row v-if="organization.legal_entity" title="Юр. Лицо:">
        <talent-link
          :path="`admin/package/legalentity/${organization.legal_entity}/change/`"
        >
          {{ organization.legal_entity }}
        </talent-link>
      </option-row>

      <option-row title="Модерация пройдена:">
        {{ organization.is_permitted ? "Да" : "Нет" }}
      </option-row>

      <option-row
        v-if="organization.is_representative"
        title="Региональный представитель:"
      >
        Данная организация является рег. представителем
      </option-row>

      <option-row
        v-if="
          organization.representatives && !!organization.representatives.length
        "
        title="Региональные представители:"
      >
        <template v-for="(item, idx) in organization.representatives">
          <talent-link
            :key="item"
            :path="`admin/package/organization/${item}/change/`"
          >
            {{ item }} </talent-link
          ><span
            v-if="idx + 1 !== organization.representatives.length"
            :key="item + 'commma'"
            >,&emsp;</span
          >
        </template>
      </option-row>

      <option-row v-if="adminLink" title="Панель администратора:"
        ><a
          :href="adminLink"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >Перейти</a
        ></option-row
      >

      <div v-if="allowListStudents">
        <a href="#" @click.prevent.stop="$emit('showStudents', organization)"
          >Смотреть список</a
        >
        учеников наставника
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { addressJoin } from "@/api/utils/dataParsers";
import dayjs from "dayjs";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";

import TalentLink from "../TalentLink.vue";

export default {
  name: "OrganizationsCard",
  components: { TalentLink },
  props: {
    organization: {
      type: Object,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    allowListStudents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenDescription: false,
    };
  },
  computed: {
    address() {
      const { organization } = this;
      if (!organization) return;
      return addressJoin(organization);
    },
    contactFullName() {
      const { organization } = this;
      if (!organization) return;
      return [
        organization.contact_last_name,
        organization.contact_first_name,
        organization.contact_middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
    organizationName() {
      const name =
        this.organization?.short_name || this.organization?.full_name;
      return name ? name : "#" + this.organization?.id;
    },
    createDate() {
      if (!this.organization?.created_at) return;
      return dayjs(this.organization.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    updatedAtDate() {
      if (!this.organization?.updated_at) return;
      return dayjs(this.organization.updated_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    adminLink() {
      if (!this.organization.id) return;
      return `${process.env.VUE_APP_TALENT_HOST}/admin/package/organization/${this.organization.id}/change/`;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
  min-height: inherit;
  width: max-content;
}

.logo {
  width: 100px;
  flex-shrink: 0;
  height: 40px;
  object-fit: contain;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid var(--v-background-base);
  border-radius: 4px;
}
</style>
